.carousel {
    margin: auto;
  }

/* background carousel App.css */
.App {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  
  .background-carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .background-carousel .slide {
    height: 100vh;
    background-size: cover;
    background-position: center;
  }
  
  .background-carousel .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .content {
    position: relative;
    z-index: 1;
    /* Adicione mais estilos conforme necessário */
  }

  /* Media query para dispositivos móveis */
@media (max-width: 768px) {
    .card-body {
      width: 100%;
    }
  }