/* BlinkingLight.css */
.light {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    /* margin: 20px auto; */
    transition: background-color 0.5s ease;
    display: inline-block;
  }
  
  .light.on {
    background-color: green;
  }
  
  .light.off {
    background-color: white;
  }
  